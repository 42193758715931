import { render, staticRenderFns } from "./SeguimientoGestor.vue?vue&type=template&id=c7201de0&"
import script from "./SeguimientoGestor.vue?vue&type=script&lang=js&"
export * from "./SeguimientoGestor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QInput,QIcon,QForm,QSelect,QBtn,QPopupProxy,QDate} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QInput,QIcon,QForm,QSelect,QBtn,QPopupProxy,QDate})
