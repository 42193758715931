
export default {
  methods: {
    marcarSeguimientoVencido() {
      if (this.data.status != this.constantesStatusCadena.seguimientoPendiente)
        return false;
      return this.dayjs(this.data.recordatorio_fecha).isBefore(
        this.dayjs().format("DD/MMMM/YYYY HH:mm:ss")
      );
    },
  },
};
