
import OportunidadService from "../../Services/OportunidadService";
import SeguimientoService from "../../Services/SeguimientoService";
import CeldaRecordatorioFecha from "./componentes/CeldaRecordatorioFecha.vue";
import HORAS from "@/Constantes/Fecha";
export default {
  name: "seguimientos-gestor",
  components: {},
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Seguimientos" }],
      filtros: {
        busqueda: "",
      },
      accionLimpiar: false,
      tipoSeguimientoOpciones: [],
      horaOpciones: HORAS.ARRAY_HORAS_POR_CUARTO,
      seguimientos: [],
      columnas: [
        {
          name: "folio",
          header: "#Seg",
          align: "center",
          field: "folio_seguimiento",
          minWidth: 90,
          width: 100,
          templateName: "folio",
          routeName: "seguimientosDetalle",
          rutaId: "oportunidad_seguimiento_id",
          type: "string",
        },
        {
          name: "folioSecundario",
          header: "#OP",
          align: "center",
          field: "oportunidad_folio",
          minWidth: 85,
          width: 85,
          templateName: "folioSecundario",
          routeName: "oportunidadesDetalle",
          rutaId: "oportunidad_id",
          type: "string",
        },
        {
          name: "nombreContacto",
          header: "Contacto",
          align: "left",
          field: "contacto_nombre_completo",
          minWidth: 200,
          width: 225,
        },
        {
          name: "articulo",
          header: "Artículo",
          align: "left",
          field: "articulo_titulo",
          minWidth: 200,
          width: 225,
          type: "string",
        },
        {
          name: "tipo",
          header: "Tipo",
          align: "left",
          field: "tipo_seguimiento_nombre",
          minWidth: 120,
          width: 120,
        },
        {
          name: "comentario",
          header: "Descripción",
          align: "left",
          field: "comentario",
          minWidth: 200,
          width: 300,
          visible: false,
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          minWidth: 130,
          width: 150,
          templateName: "statusCadena",
          statusName: "status",
          filter: false,
        },
        {
          name: "fechaRecordatorio",
          header: "Fecha",
          align: "left",
          field: "recordatorio_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 140,
          width: 140,
          templateName: () => {
            return {
              template: CeldaRecordatorioFecha,
            };
          },
        },
        {
          name: "agenteAsignado",
          header: "Agente asignado",
          align: "left",
          field: "registro_autor",
          minWidth: 160,
          width: 160,
        },
      ],
      // Variables para crear seguimiento
      modalAgregar: false,
      agregarSeguimientoObj: {
        oportunidadId: "",
        oportunidadFolio: "",
        contactoNombre: "",
        articuloTitulo: "",
        oportunidadValor: "",
        tipoSeguimientoId: "",
        fecha: "",
        hora: "",
        comentario: "",
      },
      oportunidades: [],
      columnasOportunidades: [
        {
          name: "folio",
          label: "#Op",
          field: "folio_oportunidad",
          style: "width: 5%",
          align: "center",
        },
        {
          name: "contacto",
          label: "Contacto",
          field: "contacto_nombre_completo",
          style: "width: 15%",
          align: "left",
        },
        {
          name: "articulo",
          label: "Artículo",
          field: "articulo_nombre",
          style: "width: 15%",
          align: "left",
        },
        {
          name: "registroFecha",
          label: "Registro",
          field: "registro_fecha",
          style: "width: 10%",
          align: "left",
          format: (val) => this.formatearFecha(val, 2),
        },
      ],
      modalSeleccionBusqueda: "",
      modalSeleccion: false,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {
    oportunidadSeleccionada() {
      return this.agregarSeguimientoObj.oportunidadId == "" ? false : true;
    },
    deshabilitarFecha() {
      return this.agregarSeguimientoObj.fecha == "" ? true : false;
    },
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerSeguimientos(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        ordenar: "recordatorio_fecha_asc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerSeguimientos(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await SeguimientoService.listarGestor(this.filtros)
        .then((resp) => {
          let seguimientos = resp;
          this.seguimientos = seguimientos.map((op) => {
            return {
              ...op,
              recordatorio_fecha: new Date(op.recordatorio_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await SeguimientoService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.tipoSeguimientoOpciones = resp.tiposSeguimientos;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async listarOportunidades(loader = true, busqueda = "") {
      if (loader) this.loader(true);
      let filtros = {
        status: [this.constantesStatusCadena.oportunidadEnProceso],
        ordenar: "registro_fecha_desc",
        busqueda: "",
      };
      if (this.agregarSeguimientoObj.oportunidadId == "") {
        filtros.busqueda = this.agregarSeguimientoObj.oportunidadFolio;
        this.modalSeleccionBusqueda = _.cloneDeep(
          this.agregarSeguimientoObj.oportunidadFolio
        );
      } else if (busqueda != "") {
        filtros.busqueda = busqueda;
      }

      await OportunidadService.listarDetalle(filtros)
        .then((resp) => {
          this.oportunidades = resp;
          this.abrirModalSeleccionarOportunidad();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarSeguimiento(loader = true) {
      if (this.agregarSeguimientoObj.oportunidadId == "") {
        this.mostrarAlerta(
          "Selecciona una oportunidad para continuar.",
          "error"
        );
        this.$refs.inputOportunidad.$el.focus();
        return;
      }
      let datos = {
        ...this.agregarSeguimientoObj,
      };
      // Se arma fecha de recordatorio
      if (this.agregarSeguimientoObj.fecha != "") {
        datos.recordatorioFecha = this.dayjs(
          `${this.agregarSeguimientoObj.fecha} ${this.agregarSeguimientoObj.hora}`,
          "DD/MM/YYYY h:mm a"
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (loader) this.loader(true);
      await SeguimientoService.agregar(datos)
        .then(async (resp) => {
          this.cerrarModalAgregar();
          await this.obtenerSeguimientos(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async abrirModalAgregar() {
      if (_.isEmpty(this.tipoSeguimientoOpciones))
        await this.obtenerConcentradoOpciones();
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.agregarSeguimientoObj = {
        oportunidadId: "",
        oportunidadFolio: "",
        contactoNombre: "",
        articuloTitulo: "",
        oportunidadValor: "",
        tipoSeguimientoId: "",
        fecha: "",
        hora: "",
        comentario: "",
      };
      this.modalAgregar = false;
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        // status: [],
        // registro: "",
        ordenar: "recordatorio_fecha_asc",
      };
      this.obtenerSeguimientos();
      this.accionLimpiar = true;
      this.$refs.buscar.$el.focus();
    },
    abrirModalSeleccionarOportunidad() {
      this.modalSeleccion = true;
    },
    cerrarModalSeleccionarOportunidad() {
      this.modalSeleccion = false;
      this.$nextTick(() => this.$refs.selectTipoSeguimiento.$el.focus());
    },
    manejarBusquedaModalSeleccion(busqueda) {
      if (this.agregarSeguimientoObj.oportunidadId == "")
        this.agregarSeguimientoObj.oportunidadFolio = busqueda;
      this.listarOportunidades(true, busqueda);
    },
    manejarOpcionSeleccionada(item) {
      this.agregarSeguimientoObj.oportunidadId = item.oportunidad_id;
      this.agregarSeguimientoObj.oportunidadFolio = item.folio_oportunidad;
      this.agregarSeguimientoObj.leadId = item.lead_id;
      this.agregarSeguimientoObj.contactoNombre = item.contacto_nombre_completo;
      this.agregarSeguimientoObj.articuloTitulo = item.articulo_nombre;
      this.agregarSeguimientoObj.oportunidadValor = item.valor_venta;
      this.cerrarModalSeleccionarOportunidad();
    },
    validarCampoFecha(fecha) {
      if (this.agregarSeguimientoObj.fecha == "") return true;
      return this.validarFecha(fecha, 1, false, false, true);
    },
    validarCampoHora(hora) {
      if (this.agregarSeguimientoObj.fecha == "") return true;
      return !!hora || "Hora requerida";
    },
  },
};
